<style lang="less">
.authorization {
  height: 100vh;
  text-align: center;
  .form {
    position: absolute;
    width: 384px;
    height: 400px;
    left: 0;
    right: 0;
    top: 5%;
    margin: auto;
    padding: 0 10px;
    .title {
      text-align: left;
      color: #999999;
      font-size: 24px;
      margin-bottom: 20px;
    }
    .save {
      width: 100%;
      border-radius: 24px;
      border-color: #48CEC3;
      background-color: #48CEC3;
    }
  }
  .text {
    position: absolute;
    width: 384px;
    height: 100px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 18px;
    text-align: center;
  }
}
</style>
<template>
  <div class="authorization">
    <div class="form" v-if="!showSuccess">
      <div class="title">授权邀请确认-></div>
      <Form ref="form" :model="form" :rules="formRules">
        <FormItem prop="name">
          <Input class="name" size="large" v-model="form.name" placeholder="授权人姓名">
          </Input>
        </FormItem>
        <FormItem prop="phone">
          <Input class="name" size="large" v-model="form.phone" placeholder="授权手机号">
          </Input>
        </FormItem>
        <FormItem>
          <Button class="save" type="primary" size="large" @click="save()">提交</Button>
        </FormItem>
      </Form>
    </div>
    <div class="text" v-if="showSuccess">提交成功！</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showSuccess: false,
      form: {
        idStr: null,
        name: null,
        phone: null,
      },
      formRules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {},
  methods: {
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.MyUtil.ajax()
            .post("/GoodsPermissionController/activateAuthorization", this.form)
            .then((resData) => {
              this.showSuccess = true;
            });
        }
      });
    },
  },
  created() {
    this.form.idStr = this.$route.params.idStr;
  },
};
</script>
